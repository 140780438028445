<template>
  <section class="section">
    <div class="container">
      <div class="columns is-multiline is-variable is-6">
        <div class="column is-7-desktop is-full-touch">
          <div class="content">
            <h1>{{ $t('contact.about') }}</h1>
            <div class="has-text-justified">
              <p>{{ $t('contact.summary.0') }}</p>
              <p>{{ $t('contact.summary.1') }}</p>
              <p>{{ $t('contact.summary.2') }}</p>
              <p>{{ $t('contact.summary.3') }}</p>
            </div>
            <h3>{{ $t('contact.contactInformations') }}</h3>
            <div class="is-align-center-flex">
              <b-icon
                icon="phone-square"
                size="is-medium"
              ></b-icon>
              <span class="mr-1">{{ $t('form.field.phone.label') }} :</span><a href="tel:+15143941008">514-394-1008</a>
            </div>
            <div class="is-align-center-flex">
              <b-icon
                icon="envelope-square"
                size="is-medium"
              ></b-icon>
              <span class="mr-1">{{ $t('form.field.email.label') }} :</span><a href="mailto:info@corpoexpress.com">info@corpoexpress.com</a>
            </div>
          </div>
        </div>
        <div class="column is-offset-1-desktop is-4-desktop is-full-touch">
          <contact-form></contact-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContactForm from '@/components/ContactForm';

export default {
  components: { ContactForm },
  metaInfo () {
    return {
      title: this.$t('contact.title'),
      meta: [
        { name: 'description', content: this.$t('contact.description') }
      ]
    };
  }
};
</script>
