<template>
  <div class="content">
    <h2>{{ $t('contact.contactUs') }}</h2>
    <form>
      <fieldset>
        <b-field
          :label="getFieldLabel('fullname')"
          :type="getFieldType('fullname')"
          :message="getFieldMessage('fullname')"
        >
          <b-input
            v-model="fullname"
            @blur="validateFullname"
            name="fname"
            autocomplete="given-name"
          >
          </b-input>
        </b-field>
        <b-field
          :label="getFieldLabel('enterprisename')"
          :type="getFieldType('enterprisename')"
          :message="getFieldMessage('enterprisename')"
        >
          <b-input
            v-model="enterprisename"
            @blur="validateEnterprisename"
            name="organization"
            autocomplete="organization"
          >
          </b-input>
        </b-field>
        <b-field
          :label="getFieldLabel('phone')"
          :type="getFieldType('phone')"
          :message="getFieldMessage('phone')"
          name="phone"
          autocomplete="tel"
        >
          <b-input
            v-model="phone"
            type="tel"
            @blur="validatePhone"
          >
          </b-input>
        </b-field>
        <b-field
          :label="getFieldLabel('email')"
          :type="getFieldType('email')"
          :message="getFieldMessage('email')"
          name="email"
          autocomplete="email"
        >
          <b-input
            v-model="email"
            type="email"
            @blur="validateEmail"
          >
          </b-input>
        </b-field>
        <b-field
          :label="getFieldLabel('message')"
          :type="getFieldType('message')"
          :message="getFieldMessage('message')"
          autocomplete="off"
        >
          <b-input
            v-model="message"
            maxlength="1000"
            type="textarea"
            @blur="validateMessage"
          >
          </b-input>
        </b-field>
        <b-field>
          <vue-recaptcha
            :sitekey="$CAPTCHA_SITE_KEY"
            load-recaptcha-script
            size="normal"
            @verify="onRecaptchaVerified"
            @expired="onRecaptchaExpired"
          >
          </vue-recaptcha>
        </b-field>
      </fieldset>
      <b-button
        :type="!sent ? 'is-primary' : 'is-success'"
        class="has-text-weight-semibold"
        @click="submitForm"
        :icon-right="!sent ? undefined : 'check'"
        :disabled="!canSend"
        :loading="sending"
        expanded
      >
        {{ !sent ? $t('contact.submit') : $t('contact.submitted') }}
      </b-button>
    </form>
  </div>
</template>

<script>
import api from '@/api';
import validator from '@/mixins/validator';
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'ContactForm',
  mixins: [validator(true)],
  components: { VueRecaptcha },
  data () {
    return {
      canSend: false,
      sending: false,
      sent: false,
      recaptchaToken: '',
      fullname: '',
      enterprisename: '',
      phone: '',
      email: '',
      message: ''
    };
  },
  methods: {
    async submitForm () {
      this.validate();
      if (this.errors.length)
        return;

      const payload = {
        fullName: this.fullname,
        enterpriseName: this.enterprisename,
        phoneNumber: this.phone,
        email: this.email,
        message: this.message
      };

      this.canSend = false;
      this.sending = true;

      api.createContactRequest(this.recaptchaToken, payload)
        .then(() => {
          this.sent = true;
        })
        .catch(() => {
          this.canSend = true;
        })
        .finally(() => {
          this.sending = false;
        });
    },
    onRecaptchaVerified (recaptchaToken) {
      this.canSend = true;
      this.recaptchaToken = recaptchaToken;
    },
    onRecaptchaExpired () {
      this.canSend = false;
    },
    validate () {
      this.clearErrors();

      this.validateEnterprisename();
      this.validateEmail();
      this.validateFullname();
      this.validateMessage();
      this.validatePhone();
    },
    validateEmail () {
      this.validateRegex('email', this.email, /^\S+@\S+$/);
    },
    validateEnterprisename () {
    },
    validateFullname () {
      this.validateNotEmpty('fullname', this.fullname);
    },
    validateMessage () {
      this.validateNotEmpty('message', this.message);
    },
    validatePhone () {
      this.validateMinLength('phone', this.phone, 10);
    }
  }
};
</script>
